import { createWebHistory, createRouter } from "vue-router";
import axios from "axios";
// import VueMeta from 'vue-meta'

// import store from "@/state/store";
import routes from "./routes";

// Vue.use(VueRouter)
// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: 'page',
// })

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// apply interceptor on response
axios.interceptors.response.use(
  function (response) {
    console.log(response);
    if (response.status === 200 && response.data.message) {
      console.log(response.data.message);
    }
    if (response.status === 201 && response.data.message) {
      console.log(response.data.message);
    }
    return response;
  },
  function (error) {
    // Do something with response error
    // check for errorHandle config

    // if has response show the error
    if (error.response) {
      if (error.response.status === 404 || error.response.status === 400) {
        console.log(error.response.data.message);
        localStorage.removeItem(
          `${process.env.VUE_APP_LOCAL_STORAGE_KEY_PREFIX}_user`
        );
        router.push("/login");
      } else if (error.response.status === 401) {
        // Unauthorized: Logout the user
        localStorage.removeItem(
          `${process.env.VUE_APP_LOCAL_STORAGE_KEY_PREFIX}_user`
        );
        router.push("/login");
        // You can also add a message to inform the user.
        console.log("Unauthorized: Please log in again.");
      } else {
        // Handle other error cases here
        console.log("An error occurred:", error.message);
        // You can show a generic error message to the user or take other actions.
      }
    } else {
      // No response received (API is unavailable)
      console.log("API is currently unavailable. Please try again later.");
      // You can show a message to the user or take other actions.
    }
    return Promise.reject(error);
  }
);

export default router;
